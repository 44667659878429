<template>
  <div>
    <section class="section" style="padding-top: 0px;">
      <div class="container Nunito" style="padding: 0;">
        <section class="section headerSection" style="padding-top: 20px;">
          <div class="container has-text-centered">
            <h1 class="title is-2 noselect">Status!</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Grace's status infomation.</h1>
            <h1 v-if="syncing || !data" style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">Updating Infomation...</h1>
            <h1 v-else style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">Updates every {{syncTime}}s!</h1>
          </div>
        </section>
        <section class="section" style="padding: 0;">
          <div class="columns">
            <div class="column is-centered" style="padding: 0px;">
              <div class="card options has-shadow has-text-white">
                <h1 class="title">Overview:</h1>
                <h2 class="subtitle has-text-grey is-6" style="margin-bottom: 2rem;">
                  An overview of infomation of Grace Bot.
                  <br><code>*-D</code> = Debug Mode
                </h2>
                <h1 class="title is-4">Summary:</h1>
                <h2 class="subtitle has-text-grey is-6" style="margin-bottom: 5px;">
                  Quick Summary of the bot.
                </h2>
                <h2 class="subtitle has-text-grey-light" style="font-size: 20px;" v-if="this.data">
                  Managers: {{ this.data.managers.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 }}
                  <br>Guilds: {{ this.data.info.guilds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 }}
                  <br>Users: {{ this.data.info.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 }}
                  <br>Voice: {{ Array.from(this.data.shardslist || []).map(g => g.music || 0).reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 }}
                  <!-- <br>Avg. Ping: {{(Array.from(this.data.shardslist).map(g => g.ping).reduce((a, b) => a + b, 0) / Array.from(this.data.shardslist).map(g => g.ping).length || 0)}}ms -->
                </h2>
              </div>
              <div class="card options has-shadow has-text-white" style="min-height: fit-content !important;">
                <h1 class="title">Key Legend:</h1>
                <h2 class="subtitle has-text-grey is-6" style="margin-bottom: 5px;">
                  What each color means for the status.
                </h2>
                <div style="display: flex; flex-wrap: wrap; margin-bottom: 1rem;">
                  <div class="legend has-text-success">
                    <span class="legend-marker has-background-success"></span>
                    Operational
                  </div>
                  <div class="legend has-text-info">
                    <span class="legend-marker has-background-info"></span>
                    Loading
                  </div>
                  <div class="legend has-text-warning">
                    <span class="legend-marker has-background-warning"></span>
                    Partial Outage
                  </div>
                  <div class="legend has-text-danger">
                    <span class="legend-marker has-background-danger"></span>
                    Major Outage
                  </div>
                </div>
              </div>
              <div class="card options has-shadow has-text-white" style="min-height: fit-content !important;">
                <h1 class="title">Lookup Server:</h1>
                <h2 class="subtitle has-text-grey is-6" style="margin-bottom: 5px;">
                  Lookup what shard your server is on!
                </h2>
                <b-field class="hovermessagefeild">
                  <b-input @keypress.native="(e) => {if (isNaN(parseInt(e.key, 10)) && e.key !== 'Backspace' && e.key !== 'Enter') { e.preventDefault(); }}" custom-class="input-default" maxlength="20" v-model="server" :has-counter="false" placeholder="Enter Server ID" :icon-right="server ? 'close-circle' : null" icon-right-clickable @icon-right-click="server = null"></b-input>
                  <h2 v-if="lookupshard !== null" class="subtitle has-text-grey is-6" style="margin-bottom: 5px;">
                    This server would be on shard <b>{{lookupshard}}</b>.
                    <br>
                    <i>Shard should be glowing.</i>
                  </h2>
                </b-field>
              </div>
            </div>
            <div class="column is-centered has-shadow is-two-thirds list" style="padding-bottom: 1.6rem;">
              <h1 class="title is-3 noselect" style="margin-left: 5px;">Services</h1>
              <div class="columns has-text-centered" style="margin-left: 20px; margin-right: 20px;">
                <div class="column serviceBox has-shadow Nunito" :class="{'has-text-success': data, 'has-background-danger': !data}">
                  Hub
                </div>
                <div class="column serviceBox has-shadow Nunito has-text-centered" :class="{'has-text-success': data, 'has-background-danger': !data}">
                  API
                </div>
                <div class="column serviceBox has-shadow Nunito has-text-centered" :class="{'has-text-success': data, 'has-background-danger': !data}">
                  Database
                </div>
                <div class="column serviceBox has-shadow Nunito has-text-centered" :class="{'has-text-success': data, 'has-background-danger': !data}">
                  Redis Cache
                </div>
              </div>

              <h1 class="title is-3 noselect" style="margin-left: 5px; margin-bottom: 1rem;">Managers</h1>
              <h1 v-if="!this.data || Array.from(this.data.managers).length <= 0" class="title is-3 noselect has-text-centered">No Managers found!</h1>
              <div v-else style="display: flex; flex-wrap: wrap; max-width: 100%; margin-left: 20px; margin-right: 20px;">
                <div v-for="(manager, index) in Array.from(this.data.managers)" :key="manager.id">
                  <b-tooltip type="is-info">
                    <template v-slot:content v-if="!manager">Manager {{index}} is offline</template>
                    <template v-slot:content v-else>
                      <p class="has-text-centered">{{manager.debug ? '(Debug) ' : ''}}Manager {{index}} ({{manager.id}})</p>
                      <p class="has-text-centered">UpTime: {{manager.debugInfo.uptime.format("d[ days], h[ hours], m[ mins], s[s]")}}</p>
                      <p class="has-text-centered">Running {{manager.iranShards}} shards's</p>
                      <p class="has-text-centered">Shards: {{manager.shardList.map(g => `#${g}`).join(', ')}}</p>
                      <p class="has-text-centered">Hostname: {{manager.hostname}}</p>
                      <p class="has-text-centered">Version: v{{manager.debugInfo.version}}</p>
                    </template>
                    <div class="shard has-shadow Nunito" :class="{'has-text-success': manager, 'has-background-danger': !manager}">{{ index }}{{ manager.debug ? '-D' : '' }}</div>
                  </b-tooltip>
                </div>
              </div>

              <h1 class="title is-3 noselect" style="margin-left: 5px; margin-top: 1rem;">Shards</h1>
              <h1 v-if="!this.data || Array.from(this.data.shardslist).length <= 0" class="title is-3 noselect has-text-centered">No Shards found!</h1>
              <div v-else style="display: flex; flex-wrap: wrap; max-width: 100%; margin-left: 20px; margin-right: 20px;">
                <div v-for="(shard, index) in Array.from(this.data.shardslist)" :key="shard.id">
                  <b-tooltip type="is-info">
                    <template v-slot:content v-if="!shard">Shard {{index}} is offline</template>
                    <template v-slot:content v-else>
                      <p class="has-text-centered">Shard {{shard.id}}</p>
                      <p class="has-text-centered" v-if="(shard.manager || shard.managerData).debug">Debug MODE</p>
                      <p class="has-text-centered" v-else>Production Mode</p>
                      <p class="has-text-centered">Status: {{(shard.status === 0 ? 'Online' : ([4, 8, 6, 7, 1].includes(shard.status) ? 'Loading' : (shard.status === 2 ? 'Partial Outage' : (shard.status === 5 ? 'Major Outage' : '???'))))}}</p>
                      <p class="has-text-centered">Music Nodes: {{shard.musicNodes || 0}} Active</p>
                      <p class="has-text-centered">Guilds: {{shard.guilds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</p>
                      <p class="has-text-centered">Voice: {{shard.music.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</p>
                      <p class="has-text-centered">Users: {{shard.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</p>
                    </template>
                    <div class="shard has-shadow Nunito" v-if="(shard.manager || shard.managerData).debug" :class="{'glow': lookupshard === shard.id, 'has-text-success': (shard.status === 0), 'has-text-info': ([4, 8, 6, 7, 1, 3].includes(shard.status)), 'has-text-warning': (shard.status === 2), 'has-text-danger': (shard.status === 5)}">{{ shard.id }}-D</div>
                    <div class="shard has-shadow Nunito" v-else :class="{'glow': lookupshard === shard.id, 'has-text-success': (shard.status === 0), 'has-text-info': ([4, 8, 6, 7, 1, 3].includes(shard.status)), 'has-text-warning': (shard.status === 2), 'has-text-danger': (shard.status === 5)}">{{ shard.id }}</div>

                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
  export default {
    name: 'Stats',
    components: {},
    data () {
      return {
        data: null,
        syncTime: 45,
        sync: null,
        server: null,
        syncing: false,
        lookupshard: null
      }
    },
    async beforeDestroy () {
      clearInterval(this.sync)
    },
    async created () {
      if (this.$route.query.guild_id || this.$route.query.guild || this.$route.query.id) this.server = (this.$route.query.guild_id || this.$route.query.guild || this.$route.query.id)
      this.loadStats()
      this.sync = setInterval(this.loadStats, (this.syncTime * 1000))
    },
    watch: {
      'server' () {
        this.server = (this.server || '').replaceAll(/\D/g, '') || null
        if (!this.server) this.lookupshard = null
        else if (this.server.length >= 18) {
          try {
            this.lookupshard = Number((BigInt(this.server) >> BigInt(22)) % BigInt(2))
            if ((this.lookupshard < 0 || isNaN(this.lookupshard)) && this.lookupshard !== null) this.lookupshard = null
          } catch (e) {
            console.log(e)
            this.lookupshard = null
          }
        } else this.lookupshard = null
      }
    },
    methods: {
      loadStats () {
        this.syncing = true
        return this.$store.dispatch('getApi', { path: 'plugin/shards/stats/all', params: {} }).then(async (result) => {
          // result.data.data.managers.forEach(async (item, index) => {
          //   item.shardList.forEach(async (item2, index2) => {
          //     if (result.data.data.shardslist.find(g => g.id === item2)) result.data.data.shardslist.find(g => g.id === item2).manager = item
          //   })
          // })
          for (let index = 0; index < result.data.data.managers.length; index++) {
            const element = result.data.data.managers[index]
            result.data.data.managers[index].debugInfo.uptime = this.$MomentJS.duration(result.data.data.managers[index].debugInfo.uptime || 0, 'seconds')
            for (let index2 = 0; index2 < element.shardList.length; index2++) {
              const element2 = element.shardList[index2]
              if (result.data.data.shardslist.find(g => g.id === element2)) result.data.data.shardslist.find(g => g.id === element2).manager = element
            }
          }
          this.data = result.data.data
          console.log(this.data)
          this.syncing = false
          return result
        }).catch((error) => {
          this.syncing = false
          this.data = null
          console.log(error, 'error')
          return error
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .glow {
    animation: glow 2s infinite alternate !important;
  }

  @keyframes glow {
    from {
      transform: scale(unset);
    }
    to {
      transform: scale(1.2);
    }
  }

  .legend-marker {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    margin-right: 12px;
  }
  .legend {
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shard {
    width: 50px;
    height: 50px;
    background-color: var(--main-color-bg-1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    font-weight: 500;
    border-radius: 6px;
    transition: .3s;
    cursor: pointer;
    font-size: 20px;
  }
  .shard:hover {
    filter: brightness(.8);
    -webkit-filter: brightness(.8);
    // transform: scale(1.05);
    // transition: all .3s ease-in-out;
  }

  .serviceBox {
    height: 50px;
    background-color: var(--main-color-bg-1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    font-weight: 500;
    border-radius: 6px;
    transition: .3s;
    cursor: pointer;
    font-size: 20px;
  }

  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }

  @media only screen and (max-width: 768px) {
    // #command-status-title-tag {
    //   display: none !important;
    // }
    #command-status-title-tag-2 {
      display: inherit !important;
    }
    .command {
      box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
    }
    .card-header-icon:last-child {
      display: none;
    }
    .hovermessagefeild > .help {
      display: unset !important;
    }
  }

  .command-column {
    margin-bottom: 10px;
    padding: 5px;
    padding-bottom: 0px;
  }

  .command {
    border-radius: 1px !important;
    border-color: transparent !important;
    border-width: 1px !important;
    border-radius: 10px !important;
    border-style: solid !important;
    background: var(--main-color-bg-1) !important;
    color: #fff !important;
  }
  .modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
    background-color: #f04747 !important;
  }

  .command-header-title {
    color: #fff !important;
  }

  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
  .radio:hover, .checkbox:hover {
    color: unset !important;
  }
  @media screen and (min-width: 768px) {
    .list {
      margin-left: 40px;
      margin-top: 0px !important;
    }
    .TOTAL-2 {
      margin-left: 20px;
      margin-top: 0px !important;
    }
    #level {
      width: 50%;
    }
  }
  .options {
    background: var(--main-color-bg-3) !important;
    border-radius: 10px !important;
    min-height: 200px !important;
    height: fit-content !important;
    padding: 10px;
  }
  .options:not(:first-child) {
    margin-top: 30px;
  }
  .list {
    background: var(--main-color-bg-3);
    border-radius: 10px;
    min-height: 64px;
    margin-top: 10px;
    height: fit-content;
  }
</style>

<style>
  .menu-list li ul {
    border-left: 1px solid var(--main-color-bg-3) !important;
  }
  .menu-label {
    color: lightgrey;
  }
  .menu-list a {
    color: white !important;
  }
  .menu-list a.is-active:hover {
    background-color: var(--main-color-bg-2) !important;
  }
  .menu-list a:hover {
    background-color: var(--main-color-bg-1) !important;
    color: white !important;
  }
  .menu-list a.is-active {
    background-color: #7957d5 !important;
  }
  .card {
    background-color: var(--main-color-bg) !important;
    /* height: 200px; */
    border-color: #6c757d!important;
    border: 1px solid rgba(0,0,0,.125) !important;
    border-radius: .25rem !important;
    /* cursor: pointer; */
    /* transition: .3s; */
  }
</style>
